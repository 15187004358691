import React from "react";
import {
  Typography,
  Grid,
  Box,
  Stack,
  TableHead,
  TableRow,
  TableCell,
  Table as MuiTable,
  TableBody,
} from "@mui/material";
import styled from "@emotion/styled";
import { useLocalization } from "gatsby-theme-i18n";
import _ from "lodash";
import moment from "moment";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";
import InterestedItems from "../../components/InterestedItems";
import Table from "../../components/Table";
import ToggleButtonGroupCustomized from "../../components/ToggleButtonGroupCustomized";
import HistoricalData from "../../components/HistoricalData";
import IntradayData from "../../components/IntradayData";
import SharePriceData from "../../components/SharePriceData";
import shareInformationDocs from "../../cms/shareInformationDocs/shareInformationDocs";
import analystCoverageData from "../../cms/analystCoverageData/analystCoverageData";
import capitalShareholdingInfo from "../../cms/capitalShareholdingInfo/capitalShareholdingInfo";
import capitalShareholdingData from "../../cms/capitalShareholdingData/capitalShareholdingData";
import AnnualDocTabs from "../../components/AnnualDocTabs";
import { headers } from "../../utils/navbar";
import DownloadPdfButton from "../../components/DownloadPdfButton";

const buttons = () => [
  {
    value: "price",
    label: "Current Share Price",
  },
  {
    value: "historical",
    label: "Historical",
  },
];

const dataTableDividendDistribution = () => [
  {
    title: "Cash dividends",
    headers: {
      column1: "Financial year",
      column2: "Amount (EUR m)",
      column3: "Amount per share (EUR)",
      column4: "Record date",
      column5: "Payment date",
    },
    data: [
      {
        year2023: {
          values: [
            {
              column1: <b>2023</b>,
              column2: "",
              column3: "",
              column4: "",
              column5: "",
            },
            {
              column1: "Final",
              column2: "58",
              column3: "0.0935",
              column4: "10/5/2024",
              column5: "13/5/2024",
            },
            {
              column1: <b>Total</b>,
              column2: <Typography fontWeight={700}>58</Typography>,
              column3: <Typography fontWeight={700}>0.0935</Typography>,
              column4: "",
              column5: "",
            },
          ],
        },
      },
      {
        year2022: {
          values: [
            {
              column1: <b>2022</b>,
              column2: "",
              column3: "",
              column4: "",
              column5: "",
            },
            {
              column1: "Final",
              column2: 57,
              column3: "0.09",
              column4: "12/05/2023",
              column5: "15/05/2023",
            },
            {
              column1: <b>Total</b>,
              column2: <Typography fontWeight={700}>57</Typography>,
              column3: <Typography fontWeight={700}>0.09</Typography>,
              column4: "",
              column5: "",
            },
          ],
        },
        year2021: {
          values: [
            {
              column1: <b>2021</b>,
              column2: "",
              column3: "",
              column4: "",
              column5: "",
            },
            {
              column1: "Conditional Dividend (*)",
              column2: 185,
              column3: "0.482",
              column4: "14/04/2021",
              column5: "19/01/2022",
            },
            {
              column1: "Final",
              column2: 31,
              column3: "0.05",
              column4: "26/4/2022",
              column5: "27/04/2022",
            },
            {
              column1: <b>Total</b>,
              column2: <Typography fontWeight={700}>216</Typography>,
              column3: <Typography fontWeight={700}>0.344</Typography>,
              column4: "",
              column5: "",
            },
          ],
          description: "(*) Only paid to pre-IPO shareholders",
        },
        year2020: {
          values: [
            {
              column1: <b>2020</b>,
              column2: "",
              column3: "",
              column4: "",
              column5: "",
            },
            {
              column1: "Interim",
              column2: 12,
              column3: "10.939",
              column4: "11/03/2020",
              column5: "12/03/2020",
            },
            {
              column1: <b>Total</b>,
              column2: <Typography fontWeight={700}>12</Typography>,
              column3: <Typography fontWeight={700}>10.939</Typography>,
              column4: "",
              column5: "",
            },
          ],
        },
      },
    ],
  },
  {
    title: "Historic share buyback programme",
    headers: {
      column1: "Commencement date",
      column2: "Buyback amount (EUR m)",
      column3: "Completion date",
      column4: "Shares purchased",
      column5: "Average purchase price",
    },
    data: [
      {
        year2023: {
          values: [
            {
              column1: <b>2023</b>,
              column2: "",
              column3: "",
              column4: "",
              column5: "",
            },
            {
              column1: <span>First tranche of EUR50m</span>,
              column2: "",
              column3: "",
              column4: "",
              column5: "",
            },
            {
              column1: "31/7/2023",
              column2: 50,
              column3: "19/12/2023",
              column4: "9,370,646",
              column5: "5.336",
            },
          ],
        },
      },
      {
        year2022: {
          values: [
            {
              column1: <b>2022</b>,
              column2: "",
              column3: "",
              column4: "",
              column5: "",
            },
            {
              column1: "8/11/2022",
              column2: 10,
              column3: "5/12/2022",
              column4: "1,380,322",
              column5: "7.245",
            },
          ],
        },
      },
    ],
  },
];

function ShareInvestorsLandingPage() {
  const [currentTab, setCurrentTab] = React.useState(buttons()[0].value);
  const { locale } = useLocalization();
  const data = _.groupBy(
    shareInformationDocs[locale],
    "attributes.publish_year",
  );
  const years = Object.keys(data).reverse();
  const [currentYear, setCurrentYear] = React.useState(years[0]);

  const analystDate = analystCoverageData[locale].map(({ attributes }) => ({
    ...attributes,
    analysts: attributes.analysts.split(/<\/?p>/).filter(Boolean),
  }));
  const breakIndex = Math.round(analystDate.length / 2);
  const infoTable = capitalShareholdingInfo[locale].attributes;
  const dataTable = [
    ...capitalShareholdingData[locale].map(
      ({ attributes: { title, value } }) => ({
        label: title,
        value,
      }),
    ),
    {
      label: <Typography fontWeight={600}>Total</Typography>,
      value: <Typography fontWeight={700}>100.0%</Typography>,
    },
  ];
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(headers(), "investors.elements.contact") },
    { ..._.get(headers(), "investors.elements.faqs") },
  ];

  return (
    <NewLayout byDefault apps="investors.share_information">
      <Container pb={8}>
        <Box id="price">
          <Typography mt={3} variant="h2">
            Share Information
          </Typography>
          <Box mt={7}>
            <Typography mt={3} mb={2} variant="h3">
              Allfunds Share Price
            </Typography>
            <Box>
              <ToggleButtonGroupCustomized
                items={buttons()}
                tab={currentTab}
                onChangeTab={(value) => setCurrentTab(value)}
              />
            </Box>
            <Box mt={4}>
              {currentTab === "price" && (
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <SharePriceData />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <IntradayData />
                  </Grid>
                </Grid>
              )}
              {currentTab === "historical" && <HistoricalData />}
            </Box>
          </Box>
        </Box>
      </Container>
      <Box
        id="capital"
        py={7}
        sx={{
          background: (theme) => theme.palette.primary.main,
          "*": {
            color: "white !important",
          },
        }}
      >
        <Container>
          <Typography variant="h3">Capital and Shareholding</Typography>
          <Box mb={3} mt={1.5}>
            <Typography>
              As of 31 December 2023, the share capital of Allfunds was divided
              into 620,055,702 ordinary shares created under and in accordance
              with English law, fully paid-up and with a nominal value of
              €0.0025 each.
            </Typography>
            <Typography mt={1}>
              Since 23 April 2021, the shares are listed on Euronext Amsterdam
              under the ticker symbol ‘ALLFG’ and ISIN code GB00BNTJ3546.
            </Typography>
          </Box>
          <Table headers={["Shareholder", "%"]} values={dataTable} />
          <Box mt={2}>
            <Typography
              variant="small"
              style={{ width: "100%", display: "inline-block" }}
            >
              Note: Shareholding as at{" "}
              {moment(infoTable.date).format("DD MMMM YYYY")}
            </Typography>
            <Typography variant="small" color="white">
              {infoTable.footer}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container py={7}>
        <Typography variant="h3">
          {currentYear !== "2024"
            ? "Share buyback programme – first tranche completed in December 2023"
            : "Share buyback programme - second tranche"}
        </Typography>
        <Box mt={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              {currentYear === "2022" ? (
                <StyleStack spacing={1}>
                  <Typography>
                    On 28 July 2023, Allfunds announced a €100 million share
                    buyback programme to be divided into two tranches, with a
                    first tranche of €50 million.
                  </Typography>
                  <Typography>
                    The first tranche of the share buyback programme was
                    completed in December 2023. It was executed within the
                    limitations of the existing authority granted by the general
                    meeting of shareholders on 9 May 2023 and in compliance with
                    the Market Abuse Regulations. Allfunds entered into a
                    discretionary arrangement with a financial intermediary to
                    conduct the buyback.
                  </Typography>
                  <Typography>
                    Allfunds has provided weekly updates on the progress of the
                    program via weekly press releases and reports in this
                    section of the website.
                  </Typography>
                </StyleStack>
              ) : currentYear === "2023" ? (
                <StyleStack spacing={1}>
                  <Typography>
                    On 28 July 2023, Allfunds announced the launch of a share
                    buyback programme of up to EUR 100 million.
                  </Typography>
                  <Typography>
                    The share buyback programme will be executed on Euronext
                    Amsterdam in two tranches and will last for up to 15 months.
                    The first tranche for up to €50m and up to 12.5 million
                    shares will commence in July 31st within the limitations of
                    the existing authority granted by the general meeting of
                    shareholders on 9 May 2023 and in compliance with the Market
                    Abuse Regulation.
                  </Typography>
                  <Typography>
                    Allfunds entered into a discretionary arrangement with a
                    financial intermediary to conduct the buyback.
                  </Typography>
                  <Typography>
                    Allfunds will provide weekly updates on the progress of the
                    program via weekly press releases and reports in this
                    section of the website.
                  </Typography>
                </StyleStack>
              ) : (
                <StyleStack spacing={1}>
                  <Typography>
                    On 28 July 2023, Allfunds announced the launch of a share
                    buyback programme of up to EUR 100 million.
                  </Typography>
                  <Typography>
                    The share buyback programme will be executed in two tranches
                    and will last for up to 15 months. The first tranche of up
                    to €50m or 12.5m shares was completed in 19 December 2023.
                    The second tranche for up to €50m or up to 12.5m shares
                    commenced on June 18th within the limitations of the
                    existing authority granted by the general meeting of
                    shareholders on 7 May 2024 and in compliance with the Market
                    Abuse Regulation.
                  </Typography>
                  <Typography>
                    Allfunds entered into a discretionary arrangement with a
                    financial intermediary to conduct the buyback.
                  </Typography>
                  <Typography>
                    Allfunds will provide weekly updates on the progress of the
                    program via weekly press releases and reports in this
                    section of the website.
                  </Typography>
                </StyleStack>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <AnnualDocTabs
                content={data}
                years={years}
                direction="column"
                setCurrentYear={(year) => setCurrentYear(year)}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        py={4}
        sx={{
          background: (theme) => theme.palette.primary.main,
        }}
        id="coverage"
      >
        <Container>
          <WhiteTypography variant="h3">Analyst Coverage</WhiteTypography>
          <WhiteTypography mt={1} mb={2}>
            To the best of our knowledge, the following sell-side analysts
            actively cover Allfunds
          </WhiteTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Table
                values={analystDate
                  .slice(0, breakIndex)
                  .map(({ broker, analysts }) => ({
                    broker: <b>{broker}</b>,
                    analysts,
                  }))}
                isDark
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Table
                values={analystDate
                  .slice(breakIndex)
                  .map(({ broker, analysts }) => ({
                    broker: <b>{broker}</b>,
                    analysts,
                  }))}
                isDark
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box id="dividend">
        <Container py={4}>
          <Typography mt={3} variant="h3">
            Dividend and Distribution Policy
          </Typography>
          <Stack mt={2} spacing={1}>
            <Typography>
              Allfunds is committed to maintaining a healthy CET1 ratio above
              the prevailing fully-loaded minimum capital requirement at AFB
              Banking Group level (Allfunds Bank, S.A.U. and its subsidiaries,
              together with Liberty Partners SLU). At the same time, Allfunds
              aims to offer a sustainable and attractive return to shareholders.
              As announced on our IPO Prospectus dated 16 April 2021, Allfunds'
              distribution policy is to pay-out 20% - 40% of Adjusted Net income
              (net income after adjustments, excluding extraordinary items).
              This pay-out is made in the form of cash only. Additional
              distributions could be considered periodically, taking into
              account alternative opportunities, macro-economic circumstances
              and other factors considered important by the Board of Directors.
            </Typography>
            <Typography>
              The aim is to pay a cash-only final dividend, but the Board of
              Directors may resolve to distribute an interim dividend if this is
              justified. The exact form and level of the final distribution will
              be subject to approval by the shareholders at the Annual General
              Meeting. Distribution proposals will reflect considerations that
              are relevant to a capital plan, including expected future capital
              requirements, growth opportunities available to the Group and
              regulatory developments.
            </Typography>
          </Stack>
          <Box mt={1}>
            <DownloadPdfButton
              path="https://app.allfunds.com/docs/cms/Allfunds_Group_plc_Dividend_Policy_fcfa5d0a90.pdf"
              title="Allfunds Group plc - Dividend Policy"
            />
          </Box>
          <Box>
            {dataTableDividendDistribution().map(({ title, headers, data }) => (
              <Box mt={4}>
                <Typography variant="h4">{title}</Typography>
                <MuiTable>
                  <TableHead>
                    <TableRow>
                      {Object.values(headers).map((header, index) => (
                        <TableCell align={index > 0 ? "center" : "start"}>
                          <Typography fontSize="14px">{header}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((category) => (
                      <>
                        {Object.values(category).map(
                          ({ values, description }) => (
                            <>
                              {values.map((data) => (
                                <TableRow>
                                  {Object.values(data).map((value, index) => (
                                    <TableCell
                                      align={index > 0 ? "center" : "start"}
                                    >
                                      <Typography variant="small">
                                        {value}
                                      </Typography>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                              <Typography fontSize={10} mt={2}>
                                {description}
                              </Typography>
                            </>
                          ),
                        )}
                      </>
                    ))}
                  </TableBody>
                </MuiTable>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
      <Container pb={8}>
        <InterestedItems items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

const WhiteTypography = styled(Typography)`
  color: white;
`;

const StyleStack = styled(Stack)`
  * {
    font-size: 18px;
  }
`;

export default ShareInvestorsLandingPage;
